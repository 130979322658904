import React, { createContext, useContext, useMemo } from 'react';
import { stringify, parse } from 'query-string';
import { useLocation } from 'react-router-dom';

export function indexStringify(query) {
  const search = stringify(query, {
    arrayFormat: 'index',
  });

  return search ? `?${search}` : '';
}

export function indexParse(search) {
  return parse(search, {
    arrayFormat: 'index',
    parseBooleans: true,
  });
}

export const RouterQueryContext = createContext(null);

const { Provider } = RouterQueryContext;

function RouterQuery(props) {
  const {
    //eslint-disable-next-line
    stringify = indexStringify,
    // eslint-disable-next-line
    parse = indexParse,
    ...otherProps
  } = props;

  const { search } = useLocation();
  const value = useMemo(() => {
    const query = parse(search);

    return {
      query,
      createSearch: stringify,
    };
  }, [search, parse, stringify]);

  return <Provider {...otherProps} value={value} />;
}

export function useRouterQueryValue() {
  const value = useContext(RouterQueryContext);

  if (value === null) {
    throw new Error(
      'useRouterQueryValue() may be used only in the context of a <RouterQuery> component.',
    );
  }

  return value;
}

export function useQuery() {
  const value = useContext(RouterQueryContext);

  if (value === null) {
    throw new Error('useQuery() may be used only in the context of a <RouterQuery> component.');
  }

  return value.query;
}

export function useCreateSearch() {
  const value = useContext(RouterQueryContext);

  if (value === null) {
    throw new Error(
      'useCreateSearch() may be used only in the context of a <RouterQuery> component.',
    );
  }

  return value.createSearch;
}

export default RouterQuery;

if (process.env.NODE_ENV !== 'production') {
  RouterQueryContext.displayName = 'RouterQueryContext';
}
