import { compose } from 'redux';

import { AUTH_HEADER_NAME, PRIUS_API_ENDPOINT, KNOBS_PAI_ENDPOINT } from '../constants';

import AxiosClient from '../utils/AxiosClient';
import HeaderAuth from '../utils/HeaderAuth';
import withAuth from '../utils/withAuth';
import withMock from '../utils/withMock';

const headerAuth = new HeaderAuth(AUTH_HEADER_NAME, 'Bearer');
const AuthClient =
  process.env.NODE_ENV !== 'production' && process.env.REACT_APP_MOCK === 'on'
    ? compose(withMock, withAuth(headerAuth))(AxiosClient)
    : withAuth(headerAuth)(AxiosClient);

export const phantomClient = new AuthClient({
  baseURL: PRIUS_API_ENDPOINT,
});
export const knobsClient = new AuthClient({
  baseURL: KNOBS_PAI_ENDPOINT,
});
export function authorize(token) {
  phantomClient.authorize(token);
  knobsClient.authorize(token);
}
